import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'

import './_reset.scss'
import './_global.scss'

const Layout = ({ title: siteTitle, children }) => {
  const { site: { siteMetadata: { config: { description, title, titleDivider } } } } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          config {
            author
            description
            metaTags
            repositoryUrl
            title
            titleDivider
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>{siteTitle + titleDivider + title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <Header title={title} />
      {children}
    </>
  )
}

export default Layout
