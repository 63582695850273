import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import slugify from 'slugify'

import { Image, link } from '../../utilities'

import styles from './header.module.scss'

import headerJson from '../../../.forestry/content/settings/header.json'

const Header = ({ title }) => {
  const { site: { siteMetadata: { header, contact } }, allPagesJson } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          header {
            showLogo
            showNavigationItems
            navigationItems
            showFeaturedPageButton
            featuredPage
            showFavoritePage
            favoritePage
            favoriteIcon {
              alt
              description
              image
              settings {
                disabled
                colors {
                  textColor
                  backgroundColor
                }
              }
            }
            showCallLink
            callLinkLabel
            showSearchButton
            callLinkIcon {
              alt
              description
              image
              settings {
                colors {
                  backgroundColor
                  textColor
                }
                disabled
              }
            }
            searchButtonIcon {
              alt
              description
              image
              settings {
                disabled
                colors {
                  backgroundColor
                  textColor
                }
              }
            }
            logo {
              alt
              description
              image
              title
              settings {
                colors {
                  backgroundColor
                  textColor
                }
                disabled
              }
            }
          }
          contact {
            phoneNumber
          }
        }
      }
      allPagesJson {
        nodes {
          title
          settings {
            disabled
          }
        }
      }
    }
  `)

  const pages = allPagesJson.nodes.filter(node => !node.settings.disabled).map(node => ({
    title: node.title,
    slug: node.title === 'Home' ? '/' : slugify(node.title).toLowerCase()
  }))

  console.log('pages', pages)

  return (
    <header className={styles.header}>
      {header.showLogo && (
        <>
          <Link to='/'>
            <Image className={styles.logo} src={header.logo.image} alt={header.logo.title} />
          </Link>
          <span />
        </>
      )}
      <nav className={styles.nav} role='navigation' aria-label='main navigation'>
        {header.showNavigationItems && (
          <ul className={styles.item + ' ' + styles.list}>
            {header.navigationItems.map((item, i) => {
              const title = pages.find(page => item.split('/').pop().split('.')[0] === page.slug).title
              return title && (
                <li key={i} className={styles.item}>
                  <Link activeClassName={styles.active} partiallyActive className={styles.link} to={link(item.replace('.json', ''))}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.bullet}>&#8226;</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
        {header.showFeaturedPageButton && (
          <div className={styles.item}>
            <Link className={styles.item + ' ' + styles.featured} to={header.featuredPage.replace('.forestry/content/pages', '').replace('.json', '')}>{pages.find(page => page.slug === header.featuredPage.replace('.forestry/content/pages/', '').replace('.json', '')).title}</Link>
          </div>
        )}
        {header.showFavoritePage && (
          <div className={styles.item}>
            <Link className={styles.item + ' ' + styles.verticalGrid} to={header.favoritePage.replace('.forestry/content/pages', '').replace('.json', '')}>
              <Image className={styles.favoriteIcon} src={header.favoriteIcon.image} />
              <strong>{pages.find(page => page.slug === header.favoritePage.replace('.forestry/content/pages/', '').replace('.json', '')).title}</strong>
            </Link>
          </div>
        )}
        {header.showCallLink && (
          <div className={styles.item}>
            <a className={styles.callLink + ' ' + styles.verticalGrid} href='tel:+8001234567'>
              <Image className={styles.callLinkIcon} src={header.callLinkIcon.image} />
              <span>
                {header.callLinkLabel}
                <br />
                <strong>{contact.phoneNumber.slice(0, 3) + '-' + contact.phoneNumber.slice(3, 6) + '-' + contact.phoneNumber.slice(6) + headerJson.blocks.length}</strong>
              </span>
            </a>
          </div>
        )}
        {header.showSearchButton && (
          <div className={styles.item + ' ' + styles.search}>
            <Image className={styles.searchButtonIcon} src={header.searchButtonIcon.image} />
          </div>
        )}
      </nav>
    </header>
  )
}

export default Header
