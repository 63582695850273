import React, { useContext } from 'react'
import ReactPlayer from 'react-player'
import camelcase from 'camelcase'

import { Context } from '../../context'
import { classNames, enabled, Image } from '../../../utilities'

import styles from './tabBlocks.module.scss'

export const TabBlocks = ({ block }) => {
  const { elementStyle } = useContext(Context)
  console.log('content-blocks', block)

  return (
    <section className={classNames(block, styles, 'section')} style={elementStyle(block)}>
      {block.title && <h1 className={styles.title}>{block.title}</h1>}
      <div className={styles.tabContainer}>
        {enabled(block.tabs).map((tab, i) => (
          <div className={classNames(tab, styles, 'tab')} key={i} style={elementStyle(tab)}>
            {tab.label && <h2 className={styles.label}>{tab.label}</h2>}
            <div key={i} className={styles.content}>
              {enabled(tab.content).map((block, i) => (
                <>
                  {block.template === 'rich-text' && (
                    block.content && <div key={i} className={classNames(block, styles, 'richText')} style={elementStyle(block)} dangerouslySetInnerHTML={{ __html: block.content }} />
                  )}
                  {block.template === 'image' && (
                    block.image && <Image key={i} className={classNames(block, styles, 'image')} style={elementStyle(block)} src={block.image} alt={block.alt} title={block.title} />
                  )}
                  {block.template === 'video' && block.videoUrl && (
                    <div key={i} className={classNames(block, styles, 'video')} style={elementStyle(block)}>
                      <ReactPlayer className={styles.video} light url={block.videoUrl} />
                      <p className={styles.label}>{block.label}</p>
                    </div>
                  )}
                  {block.template === 'button' && (
                    <div  key={i}className={styles.buttonContainer}>
                      <button className={classNames(block, styles, camelcase(block.style))} style={elementStyle(block)}>{block.buttonText}</button>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
