import React from 'react'
import { ContactForm, ContentBlocks, Hero, TabBlocks, Testimonial } from '../components/page'

export default (block, i) => [{
  name: 'contact-form',
  template: <ContactForm key={i} block={block} />
}, {
  name: 'filtered-images-slider',
  template: <p>{block.template}</p>
}, {
  name: 'content-blocks',
  template: <ContentBlocks key={i} block={block} />
}, {
  name: 'gallery',
  template: <p>{block.template}</p>
}, {
  name: 'hero',
  template: <Hero key={i} block={block} />
}, {
  name: 'image-bar',
  template: <p>{block.template}</p>
}, {
  name: 'images',
  template: <p>{block.template}</p>
}, {
  name: 'knowledge-base',
  template: <p>{block.template}</p>
}, {
  name: 'map',
  template: <p>{block.template}</p>
}, {
  name: 'posts',
  template: <p>{block.template}</p>
}, {
  name: 'recent-research',
  template: <p>{block.template}</p>
}, {
  name: 'research-grid',
  template: <p>{block.template}</p>
}, {
  name: 'research-slider',
  template: <p>{block.template}</p>
}, {
  name: 'tab-blocks',
  template: <TabBlocks key={i} block={block} />
}, {
  name: 'testimonial',
  template: <Testimonial key={i} block={block} />
}].find(template => template.name === block.template).template
